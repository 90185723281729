<template>
  <ui-card
    v-loading="loading"
    :heading="`${$t('actions.edit')} ${$tc('models.lesson')}`"
  >
    <lesson-form-v2
      v-if="lesson !== null"
      :lesson="lesson"
      @submit="handleSubmit"
      @cancel="handleCancel"
    />

    <!-- <lesson-form
      v-if="lesson !== null"
      :lesson="lesson"
      @submit="handleSubmit"
      @cancel="handleCancel"
    ></lesson-form>-->
  </ui-card>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
// import LessonForm from '../components/LessonForm';
import LessonFormV2 from '../refactor/LessonFormV2';
import { getLesson, updateLesson } from '../api';

export default {
  data() {
    return {
      lesson: null,
      loading: false
    };
  },

  components: {
    UiCard,
    LessonFormV2
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      try {
        await updateLesson(this.lesson);
        const message = {
          message: this.$t('feedback.update_success'),
          type: 'success'
        };
        this.$message(message);
        this.$router.back();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const message = {
          message: this.$t('feedback.update_failed'),
          type: 'error'
        };
        this.$message(message);
      }
    },

    getTranslation(transList) {
      return transList.filter(t => t.lang === this.$i18n.locale)[0].value;
    },

    handleCancel() {
      this.$router.back();
    },

    async getLesson(id) {
      this.loading = true;
      try {
        const lesson = await getLesson(id);
        this.lesson = lesson;
      } catch (err) {
        this.$message({
          message: 'klarte ikke å hente leksjon',
          type: 'error'
        });
      }
      this.loading = false;
    }
  },
  created() {
    this.getLesson(this.$router.history.current.params.id);
  }
};
</script>
